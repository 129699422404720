import Header from '@safeguard/global-header';
import Footer from './Footer';
import { useAuth0 } from '@auth0/auth0-react';
import { CONTENTFUL_SPACE_ID, CONTENTFUL_ACCESS_TOKEN } from '../constants';

import  "@safeguard/global-header/dist/index.css";  // <-- important!

interface LayoutProps {
  children: React.ReactNode;
  activeSubpage: string;
}

export default function Layout(props: React.PropsWithChildren<LayoutProps>) {
  const { children } = props;
  const { user, isAuthenticated, logout } = useAuth0();

  const displayName = user
    ? user?.given_name || user?.nickname || user?.name || "Guest"
    : "Guest";
  const email = user?.email ? user.email : "";
  const avatar = user?.picture;

  return (
    <div>
      <Header
        showLogo={false}
        user={isAuthenticated ? {
          name: displayName,
          email: email,
          picture: avatar
        } : undefined}
        onSignoutClick={() => logout()}
        sidebar={
          {
            contentful: { space: CONTENTFUL_SPACE_ID, accessToken: CONTENTFUL_ACCESS_TOKEN }
          }
        }
      />
      <div className="lg:px-24 min-w-fit">

        <main className="py-10 2xl:px-28">{children}</main>
        <Footer />
      </div>
    </div>
  );
}
